
.icon-display
  padding: 16px
  background: var(--ema-light-gray-background)
  h4
    margin-bottom: 20px
    text-align: center
    color: black
  .icon-display-card
    display: inline-block
    border: solid
    margin: 2px
    padding: 2px
    i
      font-size: 20px
    p
      font-size: 12px
.expiration-warning
  text-align: center
  color: black
  border-radius: 10px
  z-index: 2
  padding: 10px
  .expiration-warning-title
      //margin: 20px 0
  .expiration-warning-body
      margin: 15px 0
  .expiration-warning-timer
      font-weight: bold
      margin: 5px
      color: red
  .expiration-warning-btns
      >*
          margin: 10px
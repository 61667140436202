.country-region-inputs
  .inputs-comp
    border: 1px solid #ced4da
    border-radius: 8px
    background: #efefef
    select
      height: 35px
      border: none
      outline: none
      background: white
      &:first-of-type
        width: 70%
      &:last-of-type
        background: #efefef
        font-size: 12px
        width: 30%

.popover-content 
    height: 50vh
    overflow-y: auto
    white-space: pre-wrap
    &::-webkit-scrollbar 
        width: 4px
    &::-webkit-scrollbar-thumb
        background: #202f53

.filter-toggler
    padding: 3px 5px
    border: solid 1px
    border-radius: 4px
    font-size: 24px
    color: var(--secondary-text)
    cursor: pointer
    &.darkGround
        background: var(--ema-primary-blue)
        color: var(--ema-white)
    

.date-time-input
  margin-bottom: 16px
  label
    margin: 0
    font-size: 13px
    font-weight: 500
    color: #202F53
  .react-datetime-picker
    display: block
    .react-datetime-picker__wrapper
      border-radius: 5px
      padding: 2.5px 5px
      white-space: nowrap
      border-color: #ced4da
      input
        min-width: 21px
        box-sizing: border-box
        //for firefox: to remove the up&down arrows
        -moz-appearance: textfield
        &[name="year"]
          min-width: 36px
          box-sizing: unset
        &[value=""][name="month"]
          //background: pink
          min-width: 26px
      
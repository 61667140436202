:root
  --ema-white: white
  //top-menu background color
  --ema-primary-blue: #13405F 
  //button color
  --ema-light-blue: #2885C5 
  --ema-very-light-blue: #dbecf8
  --main-text: #111827
  --secondary-text: #6B7280
  //border color
  --ema-light-gray: #E5E7EB 
  
  //app background color
  --ema-light-gray-background: #f9fafd 
  --ema-table-gray: #f9fafb
  
  //status-colors
  --ema-green: #09e644
  --ema-blue: #2b88c1
  --ema-red: #FA4747
  --ema-orange: #FA8C16




   
  
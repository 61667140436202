.slider-panel-wrapper
  background: var(--ema-light-gray-background)
  margin-left: auto
  .slide-pane__content
    padding: 0 
  .slider-header
    background: var(--ema-white)
    position: sticky
    padding: 12px 16px
    width: 100%
    left: 0px
    top: 0px
    background: var(--ema-white)
    box-shadow: 0px 1px 2px rgba(18, 62, 119, 0.05)
    margin: 0px 0px
    z-index: 1
    display: flex
    justify-content: space-between
    align-items: center

    .slider-header-title
      font-weight: bold
      font-size: 20px

    .slider-header-buttons
      display: flex
      justify-content: space-between
      align-items: center

  .slider-body
    padding: 16px

  &.slide-pane_from_bottom
      height: auto
      margin-top: 100vh
      &.content-after-open
        transform: translateY(-100%)
      &.content-before-close
        transform: translateY(0%)
      &.slider-three-quarter
        height: 75%
      &.slider-half
        height: 50%
      &.slider-quarter
        height: 30%
    
.selectable-dropdown
  
  .dropdown-toggle
    background: none
    color: var(--main-text)
    display: inline-block
    font-size: 13px
    font-weight: 500
    border: 1px solid gray
    border-radius: 5px
  .dropdown-menu.show
    box-shadow: 0 0 10px var(--ema-light-gray)
.vehicle-dashboard 
  display: flex
  flex-direction: row
  justify-content: space-between
  margin-top: 10px
  padding: 15px 30px

  .vehicle-dashboard-card
    border-radius: 8px
    background-color: #fff
    box-shadow: 0 0 8px var(--ema-very-light-blue)
    // flex-grow: 1
    flex-basis: 18%
    padding: 20px

    .vehicle-dashboard-label 
      font-size: 16px
      color: var(--secondary-text)
      cursor: pointer
    .vehicle-dashboard-value
      font-weight: bold
      font-size: 22px

   


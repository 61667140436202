
.language
  img
    height: 22px
    margin-right: 8px
  .dropdown-menu.show
    padding: 16px
    box-shadow: 0 0 3px  var(--ema-light-gray)
    border-radius: 8px
    .dropdown-item
      margin: 16px 
      padding: 0
      font-size: 14px
      display: flex
      align-items: center
    .language-menu-text
      font-size: 14px
      font-weight: 500
      color: var(--main-text)
      
    
    .language-icon
      color:  var(--white-text)
      font-size: 18px
      margin-left: 9px
      

  .dropdown-toggler
    background-color: none
    border: none
    background: none
    padding: 6px
    margin-right: 10px
    display: flex
    align-items: center
    justify-content: center
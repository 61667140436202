.ema-input
  &.d-inline-block > input[type = "date"]
    width: 162px
    padding: 6px
  &.hidden
    display: none

  input
    border-radius: 8px 
    height: 35px
    //padding: 6px 12px
    &.naked-input
      border-radius: 8px !important
    &[type="file"]
      border: 1px solid #ced4da
      border-radius: 0px 8px 8px 0px

  .input-postfix
    font-size: 14px
    border-radius: 0px 8px 8px 0px !important
  .percent-input-postfix
    font-size: 14px
    background-color: #fff
  label
    margin-bottom: 3px
    font-weight: 500
    font-size: 13px
    font-family: Inter
    line-height: 21px
    color: #202F53
    margin: 0px 4px
  .search-field
      input
        background: #FFFFFF
        box-sizing: border-box
        border-radius: 8px 0px 0px 8px 

        &::placeholder
          font-family: Inter
          font-style: normal
          font-weight: normal
          font-size: 12px
          line-height: 20px
          color: #C1C9D2

      .search-field__search-icon
        border-radius: 0px 8px 8px 0px !important
        color: #C1C9Da
      .disabled
        background: #E9ECEF

  //hide updown arrow in number input
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button 
    -webkit-appearance: none

  /* Firefox */
  input[type="number"] 
    -moz-appearance: textfield
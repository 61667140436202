.elipsis-menu
  font-size: 0
  &.dropdown 
    button
      padding: 0
      margin: 0
      background: transparent
      color: var(--main-text)
      border: none
      &:focus
        box-shadow: none
      .dropdown-icon
        font-size: 25px
        vertical-align: middle
        &.mil-menu
          color: var(--main-text)
          background: #F5F7F9
          border: solid
          border-radius: 4px

    .dropdown-menu
      box-shadow: 0 0 10px var(--ema-very-light-blue)
      padding: 0
      >*
        cursor: pointer
        margin: 0
        background: none
        color: var(--main-text)
        border: none
        text-align: left
        padding: 10px
        >*
          vertical-align: middle
        i
          font-size: 22px
          margin-right: 10px
        &:hover
          opacity: 0.7
          //background: red
        &:active
          opacity: 1
          //background: #C1C9D2
    .item-disabled
      color: var(--secondary-text)
      cursor: not-allowed

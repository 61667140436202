a,
a:hover,
a:focus
  color: inherit
  text-decoration: none
  transition: all 0.3s

.nav-link.disabled
  pointer-events: auto
  cursor: not-allowed 
span
  font-style: 12px

input,button
  &[disabled]
    cursor: not-allowed

.rdt_TableHeadRow
  font-weight: bold
  font-size: 10px

i[class*="mil-"] 
  font-size: inherit
  &:not(.no-inherit)
    color: inherit

.not-available
  font-weight: bold
  opacity: 0.6
  font-style: italic
  margin: 10px auto


//react-strap
.popover
  box-shadow: 0 0 10px #808081
  max-width: 500px
.link-like
  cursor: pointer
  color: #1d4ed8
  &:hover
    color: #1d4ed8
    text-decoration: underline
    opacity: 0.7
  &:active
    color: #1d4ed8
    opacity: 1

.clickable
  cursor: pointer
  &:hover
    opacity: 0.7
  &:active
    opacity: 1

.disabled
  cursor: not-allowed
  opacity: 0.7
.ema-card
  padding: 16px
  width: 100%
  background: var(--ema-white)
  border: 1px solid var(--ema-light-gray)
  border-radius: 4px
  margin: 16px 0px
  .ema-card-title
    font-weight: 500
    font-size: 16px
    margin-bottom: 16px


.util-filter-cont
  display: flex
  margin: 14px 0
  >*
    margin-right: 10px

.cancellable-filter
  display: flex
  align-items: center
  >:first-child
    flex-grow: 1 
  .cancel-icon
    font-size: 24px
    padding: 0 0 0px  8px
    margin-bottom: 1rem
    margin-top: 21px
    cursor: pointer


.wrap-overflow
  white-space: pre-wrap

.required-red
  color: red
  margin-left: 4px
  font-size: .9rem
.error
  color: red
.loading
  position: absolute
  bottom: 50%
  right: 50%
  transform: translate(50%)
  border: 6px solid #f3f3f3
  border-radius: 50%
  border-top: 6px solid #3498db
  width: 100px
  height: 100px
  animation: spin 2s linear infinite

.custom-tabs-content
  padding: 10px 0
  padding-left: 15px
  display: flex
  .custom-tab-item
    margin-right: 15px
    cursor: pointer
    font-size: 14px

  .selected-custom-tab
    font-weight: bold
    text-decoration-line: underline
    text-underline-offset: 7px



@keyframes spin
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)

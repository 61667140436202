.operational-activity
  display: flex
  flex-direction: row
  height: 100%
  .operational-sidebar
    min-width: 250px
    height: 100%
    background: var(--ema-white)
    border-right: solid  1px var(--ema-light-gray)
    
    section
      padding: 9px 24px
      cursor: pointer
      font-weight: 600
      font-size: 14px
      &.selected
        color: var(--ema-light-blue)
        border-right: solid var(--ema-light-blue)
        background: var(--ema-very-light-blue)
    hr
      margin: 7px 24px
      width: 40px
      height: 2px
      background: var(--ema-light-gray)
      border: none

  .operational-display
    flex-grow: 1
    overflow: auto
    img
      border: solid 1px var(--secondary-text)
      padding: 5px 8px
      border-radius: 6px
      margin: 0 10px
      cursor: pointer

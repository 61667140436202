.top-menu-bar
  background: var(--ema-primary-blue)
  color: var(--ema-white)
  display: flex
  align-items: center
  padding: 4px 28px
  justify-content: space-between
  .app-logo
    margin-right: 18px
    height: 40px
  >div:first-child
    display: flex
    align-items: center
    .main-menus
      a
        padding: 4px 12px
        margin: 0px 5px
        border-radius: 4px
        cursor: pointer
        font-style: 14px
        &:hover, &.active
          background: var(--ema-light-blue)



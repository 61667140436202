@font-face {
  font-family: "font-icon";
  src: url("fonts/font-icon.eot?i68ail");
  src: url("fonts/font-icon.eot?i68ail#iefix") format("embedded-opentype"),
    url("fonts/font-icon.ttf?i68ail") format("truetype"),
    url("fonts/font-icon.woff?i68ail") format("woff"),
    url("fonts/font-icon.svg?i68ail#font-icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="mil-"],
[class*=" mil-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "font-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mil-accounting-dollar:before {
  content: "\e900";
}
.mil-accounting-f:before {
  content: "\e901";
}
.mil-accounting-o:before {
  content: "\e902";
}
.mil-account-left:before {
  content: "\e903";
}
.mil-account-right:before {
  content: "\e904";
}
.mil-activity-f:before {
  content: "\e905";
  color: #dfe4ee;
}
.mil-arrow-bg-down:before {
  content: "\e906";
}
.mil-arrow-bg-left:before {
  content: "\e907";
}
.mil-arrow-bg-right:before {
  content: "\e908";
}
.mil-arrow-bg-up:before {
  content: "\e909";
}
.mil-arrow-corner-left:before {
  content: "\e90a";
  color: #2e3a59;
}
.mil-arrow-corner-right:before {
  content: "\e90b";
  color: #2e3a59;
}
.mil-arrow-diagonal-down:before {
  content: "\e90c";
}
.mil-arrow-diagonal-up:before {
  content: "\e90d";
}
.mil-arrow-sm-down:before {
  content: "\e90e";
}
.mil-arrow-sm-left:before {
  content: "\e90f";
}
.mil-arrow-sm-right:before {
  content: "\e910";
}
.mil-arrow-sm-up:before {
  content: "\e911";
}
.mil-calendar:before {
  content: "\e912";
  color: #202f53;
}
.mil-check:before {
  content: "\e913";
  color: #48bf84;
}
.mil-check-bold:before {
  content: "\e914";
  color: #48bf84;
}
.mil-check-circle:before {
  content: "\e915";
  color: #48bf84;
}
.mil-chevron-bg-down:before {
  content: "\e916";
}
.mil-chevron-bg-left:before {
  content: "\e917";
}
.mil-chevron-bg-right:before {
  content: "\e918";
}
.mil-chevron-bg-up:before {
  content: "\e919";
}
.mil-chevron-sm-down:before {
  content: "\e91a";
}
.mil-chevron-sm-left:before {
  content: "\e91b";
}
.mil-chevron-sm-right:before {
  content: "\e91c";
}
.mil-chevron-sm-up:before {
  content: "\e91d";
}
.mil-clock-f:before {
  content: "\e91e";
}
.mil-close-bg:before {
  content: "\e91f";
}
.mil-close-sm:before {
  content: "\e920";
}
.mil-close-sm-f:before {
  content: "\e921";
}
.mil-close-sm-o:before {
  content: "\e922";
}
.mil-compliance:before {
  content: "\e923";
}
.mil-contract-f:before {
  content: "\e924";
}
.mil-contract-o:before {
  content: "\e925";
}
.mil-dashboard:before {
  content: "\e926";
  color: #dfe4ee;
}
.mil-delete-f:before {
  content: "\e927";
}
.mil-delete-o:before {
  content: "\e928";
}
.mil-detail:before {
  content: "\e929";
}
.mil-distance:before {
  content: "\e92a";
}
.mil-edit:before {
  content: "\e92b";
}
.mil-edit-o:before {
  content: "\e92c";
  color: #202f53;
}
.mil-email-at:before {
  content: "\e92d";
}
.mil-email-f:before {
  content: "\e92e";
}
.mil-email-o:before {
  content: "\e92f";
}
.mil-home-f:before {
  content: "\e930";
}
.mil-home-o:before {
  content: "\e931";
}
.mil-info-f:before {
  content: "\e932";
}
.mil-info-o:before {
  content: "\e933";
}
.mil-location-company-f:before {
  content: "\e934";
}
.mil-location-company-o:before {
  content: "\e935";
}
.mil-location-f:before {
  content: "\e936";
}
.mil-location-marker:before {
  content: "\e937";
  color: #cf2181;
}
.mil-location-o:before {
  content: "\e938";
}
.mil-lock-closed-f:before {
  content: "\e939";
}
.mil-lock-closed-o:before {
  content: "\e93a";
}
.mil-lock-opened-f:before {
  content: "\e93b";
}
.mil-lock-opened-o:before {
  content: "\e93c";
}
.mil-maintenance-f:before {
  content: "\e93d";
}
.mil-maintenance-o:before {
  content: "\e93e";
}
.mil-market-f:before {
  content: "\e93f";
  color: #dfe4ee;
}
.mil-menu:before {
  content: "\e940";
}
.mil-menu1:before {
  content: "\e941";
}
.mil-menu2:before {
  content: "\e942";
}
.mil-menu3:before {
  content: "\e943";
}
.mil-menu4:before {
  content: "\e944";
}
.mil-menu5:before {
  content: "\e945";
}
.mil-menu-duo:before {
  content: "\e946";
}
.mil-menu-fold:before {
  content: "\e947";
}
.mil-message-f:before {
  content: "\e948";
}
.mil-message-o:before {
  content: "\e949";
}
.mil-money:before {
  content: "\e94a";
}
.mil-more-horizontal:before {
  content: "\e94b";
}
.mil-more-vertical:before {
  content: "\e94c";
}
.mil-notification-f:before {
  content: "\e94d";
}
.mil-notification-o:before {
  content: "\e94e";
}
.mil-phone-f:before {
  content: "\e94f";
}
.mil-phone-o:before {
  content: "\e950";
}
.mil-plus:before {
  content: "\e951";
}
.mil-plus-circle-f:before {
  content: "\e952";
}
.mil-plus-circle-o:before {
  content: "\e953";
}
.mil-plus-square:before {
  content: "\e954";
}
.mil-recurring-o:before {
  content: "\e955";
}
.mil-report-f:before {
  content: "\e956";
}
.mil-report-o:before {
  content: "\e957";
}
.mil-save-f:before {
  content: "\e958";
}
.mil-save-o:before {
  content: "\e959";
}
.mil-search:before {
  content: "\e95a";
}
.mil-setting-f:before {
  content: "\e95b";
}
.mil-setting-o:before {
  content: "\e95c";
}
.mil-truck-delivery-f:before {
  content: "\e95d";
}
.mil-truck-delivery-o:before {
  content: "\e95e";
}
.mil-truck-f:before {
  content: "\e95f";
}
.mil-truck-fast-f:before {
  content: "\e960";
}
.mil-truck-fast-o:before {
  content: "\e961";
}
.mil-truck-frontal:before {
  content: "\e962";
}
.mil-truck-o:before {
  content: "\e963";
}
.mil-truck-pickup-f:before {
  content: "\e964";
}
.mil-truck-pickup-o:before {
  content: "\e965";
}
.mil-user-f:before {
  content: "\e966";
}
.mil-user-group-f:before {
  content: "\e967";
}
.mil-user-group-o:before {
  content: "\e968";
}
.mil-user-o:before {
  content: "\e969";
}
.mil-void-f:before {
  content: "\e96a";
}
.mil-void-o:before {
  content: "\e992";
}

.advanced-search
  position: relative
  .advance-search__result
    padding: 0 10px
    position: absolute
    z-index: 2
    background: white
    box-shadow: 0 0 5px gray
    max-height: 50vh
    overflow: auto
    .advance-search__item
      border-bottom: 1px solid #000
      margin: 10px 0
      font-size: 12px
      padding-bottom: 6px
      
      .advance-search__item-title
        font-size: 14px
        font-weight: bold
        margin-bottom: 4px
      &:hover
        cursor: pointer
        .advance-search__item-title
          color: #eea123
       
.freight-operators
.Authorized, .NotAuthorized, .Revoked, .Decommissioned,.PendingAuthorization, .Posted
  padding: 4px 4px
  border-radius: 6px
  border: solid 1px
  white-space: nowrap
  font-weight: normal
.Authorized,.Posted
  color: var(--ema-green)
.PendingAuthorization
  color: var(--ema-blue)
.NotAuthorized,.Decommissioned
  color: var(--ema-orange)
.Revoked
  color: var(--ema-red)


@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?dyu4dp");
  src: url("fonts/icomoon.eot?dyu4dp#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?dyu4dp") format("truetype"),
    url("fonts/icomoon.woff?dyu4dp") format("woff"),
    url("fonts/icomoon.svg?dyu4dp#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user-o:before {
  content: "\e900";
}
.icon-user-group-o:before {
  content: "\e901";
}
.icon-user-group-f:before {
  content: "\e902";
}
.icon-user-f:before {
  content: "\e903";
}
.icon-truck-frontal:before {
  content: "\e904";
}
.icon-truck-fast-o:before {
  content: "\e905";
}
.icon-truck-fast-f:before {
  content: "\e906";
}
.icon-setting-o:before {
  content: "\e907";
}
.icon-setting-f:before {
  content: "\e908";
}
.icon-search:before {
  content: "\e909";
}
.icon-save-o:before {
  content: "\e90a";
}
.icon-save-f:before {
  content: "\e90b";
}
.icon-plus-square:before {
  content: "\e90c";
}
.icon-plus-circle-o:before {
  content: "\e90d";
}
.icon-plus-circle-f:before {
  content: "\e90e";
}
.icon-plus:before {
  content: "\e90f";
}
.icon-phone-o:before {
  content: "\e910";
}
.icon-phone-f:before {
  content: "\e911";
}
.icon-notification-o:before {
  content: "\e912";
}
.icon-notification-f:before {
  content: "\e913";
}
.icon-more-vertical:before {
  content: "\e914";
}
.icon-more-horizontal:before {
  content: "\e915";
}
.icon-money:before {
  content: "\e916";
}
.icon-message-o:before {
  content: "\e917";
}
.icon-message-f:before {
  content: "\e918";
}
.icon-menu-v:before {
  content: "\e919";
}
.icon-menu-fold:before {
  content: "\e91a";
}
.icon-menu-duo:before {
  content: "\e91b";
}
.icon-menu5:before {
  content: "\e91c";
}
.icon-menu4:before {
  content: "\e91d";
}
.icon-menu3:before {
  content: "\e91e";
}
.icon-menu2:before {
  content: "\e91f";
}
.icon-menu1:before {
  content: "\e920";
}
.icon-menu:before {
  content: "\e921";
}
.icon-market-f:before {
  content: "\e922";
}
.icon-maintenance-o:before {
  content: "\e923";
}
.icon-maintenance-f:before {
  content: "\e924";
}
.icon-Logout:before {
  content: "\e925";
}
.icon-lock-opened-o:before {
  content: "\e926";
}
.icon-lock-opened-f:before {
  content: "\e927";
}
.icon-lock-closed-o:before {
  content: "\e928";
}
.icon-lock-closed-f:before {
  content: "\e929";
}
.icon-location-o:before {
  content: "\e92a";
}
.icon-location-f:before {
  content: "\e92b";
}
.icon-info-o:before {
  content: "\e92c";
}
.icon-info-f:before {
  content: "\e92d";
}
.icon-home-o:before {
  content: "\e92e";
}
.icon-home-f:before {
  content: "\e92f";
}
.icon-hold-o:before {
  content: "\e930";
}
.icon-fleet:before {
  content: "\e931";
}
.icon-Filter:before {
  content: "\e932";
}
.icon-email-o:before {
  content: "\e933";
}
.icon-email-f:before {
  content: "\e934";
}
.icon-email-at:before {
  content: "\e935";
}
.icon-edit:before {
  content: "\e936";
}
.icon-details:before {
  content: "\e937";
}
.icon-detail-menu:before {
  content: "\e938";
}
.icon-delete-o:before {
  content: "\e939";
}
.icon-delete-f:before {
  content: "\e93a";
}
.icon-close-sm-o:before {
  content: "\e93b";
}
.icon-close-sm-f:before {
  content: "\e93c";
}
.icon-close-sm:before {
  content: "\e93d";
}
.icon-close-bg:before {
  content: "\e93e";
}
.icon-clock-f:before {
  content: "\e93f";
}
.icon-chevron-sm-up:before {
  content: "\e940";
}
.icon-chevron-sm-right:before {
  content: "\e941";
}
.icon-chevron-sm-left:before {
  content: "\e942";
}
.icon-chevron-sm-down:before {
  content: "\e943";
}
.icon-chevron-bg-up:before {
  content: "\e944";
}
.icon-chevron-bg-right:before {
  content: "\e945";
}
.icon-chevron-bg-left:before {
  content: "\e946";
}
.icon-chevron-bg-down:before {
  content: "\e947";
}
.icon-check-circle:before {
  content: "\e948";
}
.icon-check-bold:before {
  content: "\e949";
}
.icon-check:before {
  content: "\e94a";
}
.icon-caution:before {
  content: "\e94b";
}
.icon-arrow-sm-up:before {
  content: "\e94c";
}
.icon-arrow-sm-right:before {
  content: "\e94d";
}
.icon-arrow-sm-left:before {
  content: "\e94e";
}
.icon-arrow-sm-down:before {
  content: "\e94f";
}
.icon-arrow-diagonal-up:before {
  content: "\e950";
}
.icon-arrow-diagonal-down:before {
  content: "\e951";
}
.icon-arrow-corner-right:before {
  content: "\e952";
}
.icon-arrow-corner-left:before {
  content: "\e953";
}
.icon-arrow-bg-up:before {
  content: "\e954";
}
.icon-arrow-bg-right:before {
  content: "\e955";
}
.icon-arrow-bg-left:before {
  content: "\e956";
}
.icon-arrow-bg-down:before {
  content: "\e957";
}
.icon-accounting-dollar:before {
  content: "\e958";
}

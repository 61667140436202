.file-uploader-custom-content 
  width: 560px
  border: 3px dashed var(--ema-light-gray)
  border-radius: 5px
  padding: 20px
  text-align: center
  cursor: pointer


.file-uploader-custom-button 
  background-color: var(--ema-light-blue)
  border: none
  padding: 6px 10px
  font-size: 12px
  cursor: pointer
  


.ema-btn
  display: inline-block
  padding: 5px 14px
  margin-bottom: 0
  font-size: 13px
  font-weight: 500
  line-height: 1.42857143
  text-align: center
  white-space: nowrap
  vertical-align: middle
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  background:  var(--ema-white)
  border: 1px solid gray
  border-radius: 5px
  color: var(--main-text)
  margin: 0 8px
  .icon
    font-size: 20px
  /* &.btn-disabled
    background: var(--ema-light-blue) 
    color: var(--ema-white)
    cursor: not-allowed
    border-width: .2px */
  &.btn-ema-dark
    background: var(--ema-light-blue)
    color: var(--ema-white)
    .icon
      color: var(--ema-white)
  >*
    display: inline-block
    vertical-align: middle
  >:nth-child(2)
    margin-left: 8px
  &:hover
    opacity: 0.7
  &:active
    opacity: 0.4
  &.disabled
    cursor: not-allowed

  &.bg
    padding: 10px 20px
    font-size: 18px
    .icon
      font-size: 35px
  &.sm
    padding: 4px 6px
    font-size: 12px
    margin: 0
    .icon
      font-size: 16px

  &.xs
    padding: 2px 5px
    font-size: 9px
    border-radius: 4px
    margin: 0 2px
    >:nth-child(2)
      margin-left: 4px
    .icon
      font-size: 12px

  &.block
    width: 100%
  &.img
    width: 60px
    height: 60px
    border-radius: 50%
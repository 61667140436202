
$toolbarHeight: 60px

.app-content 
  margin-left: 0
  height: 100vh
  overflow-y: auto
  width: -webkit-fill-available
  margin-left: auto
  width: 100%
  .top-menu-bar
    height: $toolbarHeight
  .view
    height: calc(100vh - $toolbarHeight)
    background: var(--ema-light-gray-background)
    .title-bar
      background: var(--ema-white)
      padding: 4px 28px
      height: 50px
      border-bottom: solid 1px var(--ema-light-gray)
      display: flex
      align-items: center
      justify-content: space-between
      .app-logo
        margin-right: 18px
        height: 40px
      .title-label
        font-size: 20px
    .summary-list
      padding: 40px 30px
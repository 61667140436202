.bordered-text
    color: #808081
    border: solid 1px var(--ema-light-gray)
    padding: 0px 6px
    border-radius: 8px
    display: inline-block
    font-size: 12px
    margin: 0
    white-space: pre-wrap
    &.sm
        font-size: 10px
        padding: 0px 4px

//new approach
=status
    padding: 4px 4px
    border-radius: 6px
    border: solid 1px
    white-space: nowrap
    font-weight: normal
.status_dark_turquoise
    color: #09c9e6
    +status
.status_blue
    color: var(--ema-blue)
    +status
.status_gold_tips
    color: #e6b209
    +status
.status_red
    color: var(--ema-red)
    +status
.status_green1
    color: #09e644
    +status
.status_dark_violet
    color: #9909e6
    +status
.status_baby_blue
    color: #2b88c1
    +status
.status_cyan
    color: #13C2C2
    +status
.status_orange
    color:#FA8C16
    +status

$status: #092ae6,#9909e6,#09c9e6,#09e644,#b6e609,#e6b209,#777649,var(--ual-red),#91d211, #FA8C16, #13C2C2
.Booked, .Inprogress, .Delivered, .Completed,  .Void, .Tender,  .Accepted, .shippingCleared, .underCustoms, .customCleared, .Loaded 
    padding: 4px 4px
    border-radius: 6px
    border: solid 1px
    white-space: nowrap
.Tender
    color: nth($status,11)
.Booked
    color: nth($status,1)
.Accepted
    color: nth($status,2)
.Inprogress
    color: nth($status,3)
.Delivered
    color: nth($status,4)
.Completed
    color: nth($status,5)
.customCleared
    color: nth($status,6)
.Void
    color: nth($status,8)
.blue, .Loaded
    color: var(--ual-dark-blue)

.shippingCleared
    color: nth($status,10)
.underCustoms
    color: nth($status,7)
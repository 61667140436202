.phone-input-label
    font-size: 13px
    font-family: Inter
    font-style: normal
    font-weight: normal
    line-height: 21px
    color: #202F53
    margin: 0px
.PhoneInputInput
    padding: 0.375rem 0.75rem
    font-size: 1rem
    line-height: 1.5
    width: 100%
    color: #495057
    background-clip: padding-box
    border: 1px solid #ced4da
    border-radius: 8px
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out
    
.PhoneInputInput:focus
    color: #495057
    background-color: #fff
    border-color: #80bdff
    outline: 0
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%)
.phone-input-div
    margin-bottom: 15px

.phone-input.phone-error
    input
        border: red solid 1px
.phone-error-message
    font-size: 80%
    color: red 
.custom-pagination
  display: flex
  justify-content: flex-end
  background: white
  padding: 10px 0
  border-top: solid 1px rgb(220,220,220)
  .custom-pagination-content
    color: gray
    font-size: 13px
    display: flex
    align-items: center
    .page-number
      margin: 0 40px
    i
      font-size: 20px
      padding: 5px
      border-radius: 50%
      cursor: pointer
      &:nth-of-type(1)
        margin-right: 20px
      &:hover
        background: rgb(230,230,230)
      &:active
        background: rgb(210,210,210)
      &.disabled
        cursor: not-allowed
        opacity: 0.7
        

@import ./assets/styles/style

body 
  font-family: 'Inter', sans-serif
  background: #fafafa
  color: #202f53
  .app-wrapper
    height: 100vh

*
  box-sizing: border-box

*::-webkit-scrollbar
  width: 8px
  
*::-webkit-scrollbar-thumb
  background: #939393
  border-radius: 3px

.Toastify__toast-container--top-right
  top: 8rem
  right: 23.4rem
  background: #000
.Toastify__toast-theme--light
  width: 141%
  margin: auto

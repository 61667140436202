.summary-list
  
  .rdt_Table
    border-left: 1px solid var(--ema-light-gray) !important
    border-right:  1px solid var(--ema-light-gray) !important
    border-radius: 15px 15px 0 0
    box-shadow: 0px 1px 2px rgba(18, 62, 119, 0.05)
    .summary-table-title-extra-space
      @media screen and (max-width: 1300px)
        word-spacing: 100vw !important
    .rdt_TableHead
        .rdt_TableHeadRow
            text-transform: uppercase
            padding: 10px 0
            background-color: var(--ema-table-gray) !important
            border-top: 1px solid var(--ema-light-gray) 
            border-left: .5px solid var(--ema-light-gray) 
            border-right: .5px solid var(--ema-light-gray) 
            border-radius: 10px 10px 0 0
            color: var(--secondary-text) !important
            font-weight: 600 !important
            @media screen and (max-width: 1399px)
                font-size: 11px !important
            @media screen and (min-width: 1400px)
                font-size: 12px !important
    .rdt_TableBody
        //min-height: 300px
        background: #fff
        // background: repeating-linear-gradient(0deg,#fff,#fff 11%,#fafafa 11%,#fafafa 22%)
        .rdt_TableRow:nth-child(even) 
          background: var(--ema-table-gray)
        .rdt_TableRow
            border: none
            padding: 10px 0
            .rdt_TableCell   
              div:first-child
                overflow: visible //potential bug

  .rdt_ExpanderRow
    border-left: solid var(--ema-light-blue)
                
  
.table-actions 
  font-size: 20px
  color: #202f53
  display: flex
  align-items: flex-end
  overflow: visible 
  >*:not(:first-child)
    margin-left: 15px
  
.ema-table
  .rdt_Table
    border-left: 1px solid var(--ema-light-gray) !important
    border-right:  1px solid var(--ema-light-gray) !important
    border-radius: 15px 15px 0 0
    box-shadow: 0px 1px 2px rgba(18, 62, 119, 0.05)
    .summary-table-title-extra-space
      @media screen and (max-width: 1300px)
        word-spacing: 100vw !important
    .rdt_TableHead
        .rdt_TableHeadRow
            padding: 10px 0
            background-color: var(--ema-table-gray) !important
            border-top: 1px solid var(--ema-light-gray) 
            border-left: .5px solid var(--ema-light-gray) 
            border-right: .5px solid var(--ema-light-gray) 
            border-radius: 10px 10px 0 0
            color: var(--secondary-text) !important
            font-weight: 600 !important
            @media screen and (max-width: 1399px)
                font-size: 11px !important
            @media screen and (min-width: 1400px)
                font-size: 12px !important
    .rdt_TableBody
        min-height: 300px
        background: #fff
        // background: repeating-linear-gradient(0deg,#fff,#fff 11%,#fafafa 11%,#fafafa 22%)
        .rdt_TableRow:nth-child(even) 
          background: var(--ema-table-gray)
        .rdt_TableRow
            border: none
            padding: 10px 0
            .rdt_TableCell   
              div:first-child
                overflow: visible //potential bug
  &.short-table
    .rdt_Table
      .rdt_TableBody
        min-height: 0
         

    
            